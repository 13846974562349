import React, { useRef, useState } from 'react';
import { showAlertMessage } from '../components/MasterComponents/CommonFunctionalities';
import Modal from '../components/Model/Modal';
import { decryptPassword } from '../components/UI Helpers/HelperFunctions/Helper';

function VideoPlayer({toogleState,setToogleState}) {
    const videoRef = useRef();
    const [errorMessage, setErrorMessage] = useState(false);

  const closeModalFn = () => setToogleState('');

    return (
        <div>
    
        <Modal status={toogleState} >
            <div className="container">
                {errorMessage ? (
                    <></>
                ) : (
                    <div className='d-flex flex-column align-items-center justify-content-center '>
                        <div className='w-100 py-5 d-flex justify-content-end'>
                            <img src="/icons/login-cross-icon.png" alt="cross-icon" className="login-cross-icon img-fluid" style={{cursor:"pointer"}} onClick={()=>{closeModalFn()}}/>
                        </div>
                    <video
                        muted
                        autoPlay
                        controls
                        src={
                            toogleState 
                        }
                        style={{ width: '80vw'}}
                        ref={videoRef}
                        controlsList="nodownload"
                    ></video>
                    </div>
                )}
            </div>
        </Modal>
            </div>
    );
}

export default VideoPlayer;


