let Settings = {
  apiCourseUrl: "/api/course",
  apiTopicalPastPapersUrl:"/api/topical_papers",
  apiExamsUrl: "/api/exam_sessions",
  apiOnlineClassesUrl: "/api/online_sessions",
  apiCampusUrl: "/api/campus",
  apiUserUrl: "/api/system_users",
  apiTopicalNotesUrl: '/api/topical_notes',
  apiContactUrl: '/api/contact',
  apiVideoUrl: '/api/videos',
  enumTypes: [ { value: 1, label: "O Level" }, { value: 2, label: "AS Level" }, { value: 3, label: "A2 Level" } ],
  enumCourseLevels: [ { value: 1, label: "Easy" }, { value: 2, label: "Intermediate" }, { value: 3, label: "Hard" } ],
  contactFormStudyLevelOptions:[ { value: 1, label: "O Level" }, { value: 2, label: "AS Level" }, { value: 3, label: "A2 Level" },{ value: 4, label: "IGCSE" } ],
};

export default Settings;