import React, { useEffect, useState } from "react";
import FieldsRenderer from "../../UI Helpers/InputFieldsRendererCommon/InputFieldsRendererComponent";
import Loader from "../../UI Helpers/Simple Loading/Loader";
import { checkIfArrayIsEmpty, gridLinkValueFormatter, showAlertMessage } from "../../MasterComponents/CommonFunctionalities";
import { validateForm } from "../../UI Helpers/HelperFunctions/Helper";
import { useLocation, useNavigate } from "react-router-dom";
import CommonCalls from "../../CommonAPICalls/CommonCalls";
import Settings from "../../CommonAPICalls/Settings";


const apiCall = new CommonCalls();

const DetailViewSystemUser = () => {
  const [saveBtn, setSaveBtn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingExamAttendances, setLoadingExamAttendances] = useState(true);

  const [formInputData, setFormInputData] = useState("");

  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [popupDetails, setPopupDetails] = useState({});
  const [examAttendance, setExamAttendance] = useState([]);
  const [examDetails, setExamDetails] = useState([]);
  const [campusesList, setCampusesList] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const userId = params.get("userid");

  const getUserDetails = async () => {
    const response = await apiCall.getUserById(userId);

    if (response) {

      setFormInputData({
        email: response?.email,
        full_name: response?.full_name,
        phone_number: response?.phone_number,
        password: response?.password,
        student_category: response?.student_category,
        is_admin: response?.is_admin,
        student_campus_id: response?.student_campus_id,
        portal_access: response?.portal_access
      });
    }
    setLoading(false);
  };

  const getUserExamDetailsOptions = async () => {
    const apiResponse = await apiCall.getExamDetails();
    if (apiResponse) {
      setExamDetails(apiResponse?.data?.map((item) => {
        return {
          value: item?.exam_id,
          label: `Topic: ${item?.exam_topic} - T.Days: (${item?.attendance_out_of}) - T.Marks: (${item?.marks_out_of})`
        }
      }));
    }
  };


  const getUserCampusOptions = async () => {
    const apiResponse = await apiCall.getSystemCampuses();
    if (apiResponse) {
      setCampusesList(apiResponse?.data?.map((item) => {
        return {
          value: item?.campus_id,
          label: item?.campus_name
        }
      }));
    }
  };

  const getUserExamAttendanceDetails = async () => {
    setLoadingExamAttendances(true);
    try {
      const apiResponse = await apiCall.getExamAttendanceDetails(userId);
      if (apiResponse) {
        setExamAttendance(apiResponse?.data);
        setTotalRecords(apiResponse?.data?.length);
        setLoadingExamAttendances(false);
      }
    } catch (err) {
      setExamAttendance([]);
      setTotalRecords(0);
      setLoadingExamAttendances(false);
    }
  }

  useEffect(() => {
    const getData = async () => {

      setLoading(true);
      await getUserExamDetailsOptions();
      await getUserCampusOptions();
      if (userId) {
        setLoading(true);
        getUserDetails();
        getUserExamAttendanceDetails();
      } else {
        showAlertMessage("Could not Find User", "fail");
        navigate("/portal");
      }
    }
    getData();
  }, [userId]);

  const inputsFieldAr = [
    {
      label: "Email",
      className: "col-lg-4",
      inputType: "text",
      name: "email",
      id: "email",
      required: true,
    },
    {
      label: "Full Name",
      className: "col-lg-4",
      inputType: "text",
      name: "full_name",
      id: "full_name",
      required: true,
    },
    {
      label: "Phone Number",
      className: "col-lg-4",
      inputType: "number",
      name: "phone_number",
      id: "phone_number",
      required: true,
    },
    {
      label: "Password",
      className: "col-lg-4",
      inputType: "password",
      name: "password",
      id: "password",
    },
    {
      label: "Student Category",
      className: "col-lg-4",
      options: Settings.enumTypes,
      inputType: "dropdown",
      name: "student_category",
      id: "student_category",
      required: true
    },
    {
      label: "Student Campus",
      className: "col-lg-4",
      options: campusesList,
      inputType: "dropdown",
      name: "student_campus_id",
      id: "student_campus_id",
      required: true
    },
    {
      label: "Is Admin",
      className: "col-lg-2 col-6 my-4",
      inputType: "checkbox",
      name: "is_admin",
      id: "is_admin",
    },
    {
      label: "Portal Access",
      className: "col-lg-2 col-6 my-4",
      inputType: "checkbox",
      name: "portal_access",
      id: "portal_access",
    },
  ];

  const onDeleteSystemExamAttendance = async (rowItems) => {
    if (checkIfArrayIsEmpty(rowItems)) {
      showAlertMessage('Please select any rows to delete!', 'fail');
      return;
    };

    setLoadingExamAttendances(true);

    for (const item of rowItems) {
      try {
        const apiResponse = await apiCall.removeSystemExamAttendance(item?.examination_attendance_id);
        if (apiResponse) {
          showAlertMessage(apiResponse?.message, 'success');
        }
      } catch (error) {
        showAlertMessage(error, 'fail');
      }
    };

    getUserExamAttendanceDetails();
  };

  // const userExamAttendanceModule = () => {
  //   const gridColumnHeaders = [
  //     { field: 'user_attendance', cellRenderer: gridLinkValueFormatter, customRendererLink: true, columnName: 'User Attendance' },
  //     { field: 'user_marks', columnName: 'User Marks' },
  //     { field: 'exam_id', inputType: "dropdown", options: examDetails, columnName: 'Exam' } 
  //   ];

  const onExamAttendanceClicked = (row, colKey) => {
    if (colKey === 'user_attendance') {
      setPopupDetails({
        examination_attendance_id: row?.examination_attendance_id,
        openPopup: true
      });
    };
  };

  //   return (
  //     <CustomDataGrid
  //       columns={gridColumnHeaders} 
  //       data={examAttendance} 
  //       pageSize={pageSize} 
  //       loadingState={loadingExamAttendances}
  //       pagination={false}
  //       onAddClick={() => setPopupDetails({ examination_attendance_id: null, openPopup: true }) }
  //       onCellClicked={onExamAttendanceClicked}
  //       onSelectedRowsDelete={onDeleteSystemExamAttendance}
  //       totalRecords={totalRecords}
  //       currentPage={pageNumber}
  //       onPageChange={setPageNumber}
  //       onPageSizeChange={setPageSize}
  //     />
  //   )
  // };

  const updateUserData = async () => {
    setSaveBtn(true);
    let validationResponse = validateForm(inputsFieldAr, formInputData);
    if (!validationResponse) {
      setSaveBtn(false);
      return;
    }
    const response = await apiCall.updateUser(userId, formInputData);
    if (response?.status === 202) {
      setSaveBtn(false);
      showAlertMessage(response?.message, "success");
      return response;
    } else {
      setSaveBtn(false);
      showAlertMessage(response?.message, "fail");
      return;
    }
  };
  return (
    <>
      {loading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "80vh" }}
        >
          <Loader />
        </div>
      ) : (
        <div className="user-detail-view-container container-fluid ps-4 pe-4">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setSaveBtn(true);
              updateUserData();
            }}
          >
            <div className="w-100 text-end ">
              <button
                type="submit"
                className="connect-button w-fit p-3"
                disabled={saveBtn}
              >
                <div>
                  <span> Save </span>{" "}
                  {saveBtn ? (
                    <output
                      className="spinner-border spinner-border-sm ms-1"
                      aria-hidden="true"
                    ></output>
                  ) : (
                    ""
                  )}
                </div>
              </button>
            </div>
            <FieldsRenderer
              fieldsArray={inputsFieldAr}
              updateState={setFormInputData}
              currentState={formInputData}
            />
          </form>

        </div>
      )}
      {/* { popupDetails?.openPopup && <DetailViewExamAttendancePopup examDetailsArray={examDetails} reloadParentGrid={getUserExamAttendanceDetails} user_id={userId} openPopup={popupDetails?.openPopup} setPopupState={setPopupDetails} examination_attendance_id={popupDetails?.examination_attendance_id} /> } */}
    </>
  );
};

export default DetailViewSystemUser; 