import React, { useState,useEffect } from "react";
import "./ProfilePopup.css";
import Loader from "../../UI Helpers/Simple Loading/Loader";
import CommonCalls from "../../CommonAPICalls/CommonCalls";
import { showAlertMessage } from "../../MasterComponents/CommonFunctionalities";
import FieldsRenderer from "../../UI Helpers/InputFieldsRendererCommon/InputFieldsRendererComponent";
import { validateForm } from "../../UI Helpers/HelperFunctions/Helper";
import { Spinner } from "react-bootstrap";

const inputsFieldAr = [
  {
    label: "Full Name",
    className: "col-lg-6",
    inputType: "text",
    name: "fname",
    id: "fname",
    required:true
  },
  {
    label: "Phone Number",
    className: "col-lg-6",
    inputType: "text",
    name: "phone",
    id: "phone",
    required:true
  },
  {
    label: "Email",
    className: "col-lg-6",
    inputType: "text",
    name: "email",
    id: "email",
    required:true
  },
  {
    label: "Password",
    className: "col-lg-6",
    inputType: "password",
    name: "password",
    id: "password",
    required:true
  },
]

function ProfilePopup({closeModal}) {
  const [formInputData , setFormInputData] = useState("")
  const [loading, setLoading] = useState(false)
  const [intialLoading, setIntialLoading] = useState(true)
  const [error,setError] = useState(false)

  const apiCall = new CommonCalls();


  

    const updateUser = async()=>{
      setLoading(true)
      const userData ={
        full_name:formInputData.fname,
        email:formInputData.email,
        phone:formInputData.phone,
        password:formInputData.password
      }
      let validationResponse = validateForm(inputsFieldAr,formInputData);
      if(!validationResponse)
        {
          setLoading(false)
          return
        }
      const response = await apiCall.updateUserProfile(userData);
      if(response?.status === 202)
      {
          setError(false)
          setLoading(false)
          if(response?.length === 0)
          {
            showAlertMessage(response?.message,"fail")
            setError("Could not send data to server")
            return 
          }
          showAlertMessage(response?.message,"success")
          return response
      }
      else
      {
          setLoading(false)
          showAlertMessage("could not update profile","fail")
          setError("Could not send data to server")
          return 
      }
    }

    const getUserDetial = async()=>{
      const response = await apiCall.getUserDetails()
      console.log(response,"hello");
      if(response?.response?.status == 200)
        {
        console.log(response.response.data,"hello");
        
        setFormInputData(
          {
            fname:response?.response?.data?.full_name,
            phone:response?.response?.data?.phone_number,
          }
        )
        setLoading(false)
        
      }
      setIntialLoading(false)
    }

useEffect(()=>{
  getUserDetial()

},[])

  return (
    <div className="ProfilePopup-container container">
        <div className="text-end w-100 pt-3">
            <img
                onClick={closeModal}
                src='/images/icons/close-icon-dark.svg'
                alt="cross icon"
                className="icon-cross"
            />
        </div>
      <div className="row no-gutters">
        <div className="col-12">
          <div className="test-marks-popup-box">
            <div className="">
                <h2 className="h1_main mb-0">User Profile Update</h2>
                
            </div>
            {
              intialLoading ?
              <div style={{height:"400px",width:"100%"}}
              className="d-flex align-items-center justify-content-center"
              >
              <Spinner
                style={{color:"red"}}
              />
              </div>
              :
              <form onSubmit={(e)=>{
                e.preventDefault()
                updateUser()
              }}>
                <div className=" my-2 gy-4">
                  
                    <FieldsRenderer
                        fieldsArray={inputsFieldAr}
                        updateState={setFormInputData}
                        currentState={formInputData}
                      />
                </div>
                <button className="genral-btn mt-4" type="submit" disabled={loading}>
                <div><span> Update </span> {loading ? <output className="spinner-border spinner-border-sm ms-1" aria-hidden="true"></output> : ''}</div>
                </button>
              </form>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfilePopup;
