import React, { useEffect, useState } from "react";
import NewsAndEvents from '../../components/LMS Portal/NewsAndEvents/NewsAndEvents';
import HeaderSliderWraper from "../../components/LMS Portal/HeaderSliderWraper/HeaderSliderWraper";
import CourseCard from "../../components/CourseCard/CourseCard";
import ChemistryATPSessionPartTwo from '../../components/LMS Portal/Enrolled Courses/ChemistryATPSessionPartTwo/ChemistryATPSessionPartTwo';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import EnrolledPastPapers from "../../components/LMS Portal/EnrolledPastPapers/EnrolledPastPapers";
import PastPaperCard from "../../components/LMS Portal/PastPaperCard/PastPaperCard";
import SystemCoursesListings from "../../components/LMS Portal/SystemCourses/SystemCoursesListings";
import SystemExamsListings from "../../components/LMS Portal/SystemExams/SystemExamsListings";
import SystemNotesListings from "../../components/LMS Portal/SystemNotes/SystemNotesListings";
import SystemOnlineClassesListings from "../../components/LMS Portal/SystemOnlineClasses/SystemOnlineClassesListings";
import SystemTopicalPastPapersListings from "../../components/LMS Portal/SystemTopicalPastPapers/SystemTopicalPastPapersListings";
import SystemUsersListings from "../../components/LMS Portal/User/SystemUsersListings";
import UserMarksAttendance from "../../components/LMS Portal/UserMarksAttendance/UserMarksAttendance"; 
import Loader from "../../components/UI Helpers/Simple Loading/Loader";
import { checkUser } from "../../store/actions/userActions";
import SystemCampusesListings from "../../components/LMS Portal/SystemCampuses/SystemCampusesListings";
import StudentMarksListing from "../../components/LMS Portal/StudentsMarks/StudentMarksListing";
import VideoUploaderAWS from "../../components/LMS Portal/VideosUploader/VideoUploaderAWS";

function AdminMain({children=null , selctedTabNumber = null}) {
  const [currentScreen, setCurrentScreen] = useState(-1);
  const [loading,setLoading] = useState(true)
  const [noAccess,setNoAccess] = useState(false)
  const navigate = useNavigate()
  const location = useLocation();

  
  const changeScreen = (screenNumber) => {
    setCurrentScreen(screenNumber);
  };
  const sideOptionsUser = [
    {
      image: "/images/icons/user-side-dashboard-icon-new.svg",
      title: "Dashboard",
      component: <NewsAndEvents changeScreenFunc={changeScreen}/>
    },
    {
      image: "/images/icons/user-side-enrolled-icon-new.svg",
      title: "Enrolled Courses",
      component: <ChemistryATPSessionPartTwo changeScreenFunc={changeScreen}/>
    },
    {
      image: "/images/icons/user-side-enrolled-paper-icon-new.svg",
      title: "Enrolled Past Papers",
      component: <EnrolledPastPapers changeScreenFunc={changeScreen}/>
    },
    {
      image: "/images/icons/user-side-marks-icon-new.svg",
      title: "Marks & Attendence",
      component: <UserMarksAttendance />
    },
    {
      image: "/images/icons/user-side-class-icon-new.svg",
      title: "Online Classes",
      component: <SystemOnlineClassesListings studentPortal={true} />
    }, 
    {
      image: "/images/icons/user-side-course-icon-new.svg",
      title: "Other Courses",
      component: <CourseCard adminCourse={true} />
    }, 
    {
      image: "/images/icons/user-side-past-paper-icon-new.svg",
      title: "Other Past Paper",
      component: <PastPaperCard/>
    }, 
  ];
  const sideOptionsAdmin = [
    {
      image: "/images/icons/admin-slider-user-icon.svg",
      title: "System User",
      component: <SystemUsersListings/>
    },
    {
      image: "/images/icons/admin-slider-course-icon.svg",
      title: "System Courses",
      component: <SystemCoursesListings/>
    },
    {
      image: "/images/icons/admin-slider-exam-icon.svg",
      title: "Exams",
      component: <SystemExamsListings/>
    },
    {
      image: "/images/icons/user-side-past-paper-icon-new.svg",
      title: "Topical Past Papers",
      component: <SystemTopicalPastPapersListings/>
    },
    {
      image: "/images/icons/admin-slider-notes-icon.svg",
      title: "Notes",
      component: <SystemNotesListings/>
    },
    {
      image: "/images/icons/admin-slider-class-icon.svg",
      title: "Online Classes",
      component: <SystemOnlineClassesListings />
    },
    {
      image: "/images/icons/admin-slider-campus-icon.png",
      title: "Campuses",
      component: <SystemCampusesListings />
    },
    {
      image: "/images/icons/admin-slider-student-marks-icon.png",
      title: "Students Marks",
      component: <StudentMarksListing />
    },
    {
      image: "/images/video-play.png",
      title: "Videos Upload",
      component: <VideoUploaderAWS />
    }
  ];
  
  const [currentSideOptions, setCurrentSideOptions] = useState(sideOptionsUser);
  const dispatch = useDispatch()
  const userLogin = useSelector((state)=> state.userLogin)
  
  useEffect(()=>{
    const checkUserRole = ()=>{
      if(!userLogin.userInfo && localStorage.getItem('userInfo'))
      {
        return
      }
      if(userLogin.userInfo?.is_admin)
        {
          setCurrentSideOptions(sideOptionsAdmin)
          
          const tabId = location.state?.activeTab;
          setLoading(false)
          if(tabId && tabId < currentSideOptions.length && tabId >= 0 )
          {
            setCurrentScreen(tabId)
            return
          }
          setCurrentScreen(0)
        }
        else if(userLogin.userInfo?.portal_access)
        {
          setCurrentSideOptions(sideOptionsUser)
          const params = new URLSearchParams(location.search);
          const tabId = params.get("tab_id");
          dispatch(checkUser());
          setLoading(false)
          if(tabId && tabId < currentSideOptions.length && tabId >= 0)
          {
            setCurrentScreen(tabId)
            return
          }
          setCurrentScreen(0)
        }
        else
        {
          setCurrentScreen(-1);
          setLoading(false);
          setNoAccess(true); 
        }      
    }

    checkUserRole()
  },[userLogin, location.search])
  return (
    <>
      {
        loading ?
        <div style={{height:"100vh"}} className="d-flex align-items-center justify-content-center">
          <Loader/>
        </div>

        :
          <>
            {
              noAccess?
              <div style={{height:"100vh"}} className="d-flex flex-column align-items-center justify-content-center"> 
                <div style={{background:"red",color:"white",borderRadius:"20px",textAlign:"center",padding:"20px",}}>
                  <h4 className="h1_main mb-0" style={{color: '#fff'}}>Cannot access portal, please contact your administrator. Navigating you back to our website...</h4>
                </div>
                <button className="py-4 connect-button" onClick={()=>{navigate("/")}}> Go Back</button>
              </div>
              :
              <>
               <HeaderSliderWraper changeScreen={changeScreen} selctedTabNumber={selctedTabNumber ?? currentScreen} sideOptions={currentSideOptions}>
                {children ?
                  children
                  :
                  currentSideOptions[currentScreen]?.component
                }
                </HeaderSliderWraper>
              </>
            }
           
          </>
      }
    </>
  );
}

export default AdminMain;