import React from 'react';
import Checkbox from './Checkbox';
import DropDownInput from './DropDownInput';   
import InputDatePicker from './InputDatePicker';
import Input from './Input';
import MultiSelect from './MultiSelect';
import { showAlertMessage } from '../../MasterComponents/CommonFunctionalities';
import RadioGroup from './RadioGroup';
import CheckboxGroup from './CheckboxGroup';

//Component For Mapping Field Input Boxes.

// ** Props 
    {/* 
        mainClassName,
        className, 
        mainTitle,
        required,
        inputClassName,
        disable,
        type,
        inputType,
        label,
        isChecked,
        value,
        gridObjectFunction,
        id 
    */}
// ----

// let textBoxInputSettings = [ 
//     {label:Helper.getLabel(props.language,'',""), className: '', inputType: "text/number/checkbox/datepicker/dropdown", name:'', id:'', }
// ]

const FieldsRenderer = ({ fieldsArray, updateState, currentState }) => {
    const onInputCheckboxChangeHandler = (e) => {
        const { id, checked } = e.target;  
        updateState((prevState) => {
            return {
                ...prevState,
                [id]: checked  
            }
        })
    }

    const onInputDatePickerChangeHandler = (name, value) => {
        updateState((prevState) => {
            return {
                ...prevState,
                [name]: value
            }
        })
    } 

    const onInputChangeHandler = (e) => {
        const {name, value} = e.target;

 
        updateState((prevState) => {
            return {
                ...prevState,
                [name]: value
            }
        })   
    }
    const onInputCheckboxListChangeHandler = (event, newCheckedState, name) => {
        const selectedOption = newCheckedState.find(option => option.isChecked);
    const selectedValue = selectedOption ? (selectedOption.isOther ? selectedOption.inputValue : selectedOption.label) : '';

    updateState((prevState) => {
      return {
        ...prevState,
        [name]: selectedValue
      };
    });
    
      };

      const handleCheckboxGroupListChange = (event, selectedValues, name) => {
        updateState((prevState) => {
          return {
            ...prevState,
            [name]: selectedValues
          };
        });
      };
    
    const onInputPhoneChangeHandler = (e) => {
        const { name, value } = e.target;
    
        const phoneNumberRegex = /^\d+$/;
    
        if (phoneNumberRegex.test(value)) {
            updateState((prevState) => {
                return {
                    ...prevState,
                    [name]: value
                };
            });
        } else {
            showAlertMessage("Input must be a valid Phone number", "fail");
        }
    };
    
    const handleSelectionChange = (selectedValues,name) => {
        updateState((prevState) => {
            return {
                ...prevState,
                [name]: selectedValues
            }
        }) 
      };
    return (
        <div className='container-fluid m-0 p-0'>
            <div className='row'>
                {fieldsArray.map((element, index) => { 
                    return (
                        <div className={element.className ? element.className : 'col-lg-6'} key={index}>
                            {element.inputType == "text" &&  
                                <Input label={element.label} type='text' id={element.id ? element.id : null} required={element.required ? element.required : false} disabled={element.disable ? element.disable : false} name={element.name} onChange={onInputChangeHandler} value={currentState[element.name]} placeholder={element.placeholder}/>
                            }
                            {element.inputType == "phone" &&  
                                <Input label={element.label} type='number' id={element.id ? element.id : null} required={element.required ? element.required : false} disabled={element.disable ? element.disable : false} name={element.name} onChange={onInputPhoneChangeHandler} value={currentState[element.name]} placeholder={element.placeholder}/>
                            }
                            {element.inputType == "number" &&   
                                <Input maxValue={element?.maxValue} label={element.label} type='number' id={element.id ? element.id : null} required={element.required ? element.required : false} disabled={element.disable ? element.disable : false} name={element.name} onChange={onInputChangeHandler} value={currentState[element.name]+''} placeholder={element.placeholder} />
                            }
                            {element.inputType == "dropdown" && 
                                <DropDownInput id={element.id ? element.id : null} required={element.required ? element.required : false} disabled={element.disable ? element.disable : false} label={element.label} options={element.options ? element.options : []} value={currentState[element.name]} defaultSelected={currentState[element.name]} name={element.name} onSelect={onInputChangeHandler} />
                            }
                            {element.inputType == "multi_dropdown" && 
                                <MultiSelect defaultValues={element.defaultValues} onChange={handleSelectionChange} name={element.name} Label={element.label} enableSearch={element.enableSearch} />  
                            }
                            {element.inputType == "checkbox_group" && 
                                <CheckboxGroup id={element.id ? element.id : null} options={element?.options} onChange={handleCheckboxGroupListChange} selectOne={element.selectOne} name={element.name} label={element.label} required={element.required ? element.required : false}/>  
                            }
                            {element.inputType == "checkbox" &&
                                <Checkbox id={element.id ? element.id : null} type={element.inputType} name={element.name} onChange={onInputCheckboxChangeHandler} disableWithoutIcon={element.disable ? element.disable : false} label={element.label} isChecked={element.checked ? true : currentState[element.name]} disabled={element.disable ? element.disable : false} /> 
                            } 
                            {element.inputType == "radioList" &&
                                <RadioGroup  id={element.id ? element.id : null} options={element?.options} onChange={onInputCheckboxListChangeHandler} name={element.name} label={element.label} required={element.required ? element.required : false}/> 
                            } 
                            {element.inputType == "datepicker" &&
                                <InputDatePicker empty={element.emptyDate} timePicker={element.timePicker} id={element.id ? element.id : null} disable={element.disable ? element.disable : false} value={currentState[element.name]} label={element.label} name={element.name}  required={element.required ? element.required : false} onChange={onInputDatePickerChangeHandler}  /> 
                            }
                            {element.inputType == "textarea" && 
                                <div className='textarea_default'> 
                                    <label className='mb-2'>{element.label ? element.label : null}</label>
                                    <textarea id={element.id ? element.id : null} disable={element.disable ? element.disable : false} value={currentState[element.name]} name={element.name} inputClassName={element.inputClassName} required={element.required ? element.required : false} onChange={onInputChangeHandler}  /> 
                                </div>
                            }
                            {element.inputType == "password" && 
                               <Input inputType='password_eye' id={element.id ? element.id : null} required={element.required ? element.required : false} disable={element.disable ? element.disable : false} name={element.name} label={element.label} onChange={onInputChangeHandler} value={currentState[element.name]} />
                            }
                        </div>
                    )
                })}
            </div>
        </div> 
    )
};

export default FieldsRenderer;