import React from 'react';
import { showAlertMessage } from '../../../components/MasterComponents/CommonFunctionalities';

const FileUploaderWidget = ({setSelectedFile, selectedFile}) => {
    const handleFileChange = (event) => {
        const file = event.target.files[0]; 
        if (file) { 
            if (file.type === 'video/mp4') { 
                if (file.size <= 1024 * 1024 * 1024) { 
                    setSelectedFile(file); 
                } else {
                    showAlertMessage('File size exceeds 1GB limit!', 'fail');
                }
            } else {
                showAlertMessage('Please select an MP4 video file!', 'fail');
            }
        }
    };

    return (
        <div className='uploader-component-wrapper'>
            <div className='upload'>
                <img alt="upload" src="/icons/upload.svg" />
                <div>
                    {selectedFile ? (
                        <h3 className="h3_main">Selected: <p className='para_main'>{selectedFile?.name}</p></h3>
                    ) : (
                        <>
                            <h3 className="h3_main">Select a video file or drag and drop here</h3>
                            <p className="para_main">MP4, file size no more than 1 GB</p>
                        </>
                    )}
                </div>

                <input
                    type="file"
                    accept="video/mp4"  
                    onChange={handleFileChange}
                    style={{ display: 'none' }} 
                    id="fileInput"
                />
                <label htmlFor="fileInput" className="button_main">SELECT FILE</label>
            </div>
        </div>
    );
};

export default FileUploaderWidget;