import React,{useState,useEffect} from 'react';
import './Curriculum.css';
import Modal from '../Model/Modal';
import TestMarksPopup from '../LMS Portal/User/TestMarksPopup';
import Settings from '../CommonAPICalls/Settings';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../../store/actions/cartActions';
import CommonCalls from '../CommonAPICalls/CommonCalls';
import { showAlertMessage } from '../MasterComponents/CommonFunctionalities';
import { decryptPassword } from '../UI Helpers/HelperFunctions/Helper';
import VideoPlayer from '../../screens/VideoPlayer';

const apiCall = new CommonCalls();

const Curriculum = ({courseDetails}) => {

  const [showPreview , setShowPreview] = useState(false)
  const [lecturesDetails , setLecturesDetails] = useState([])
  const [loading , setLoading] = useState(true)
  const [error,setError] = useState(false)
  const [showVideoLecture,setShowVideoLecture] = useState("")
  const userLoginStatus = useSelector((state)=> state.userLogin)
  const dispatch = useDispatch()
  const closeModal = () => setShowPreview(false);

  const getLectureStatus = (lecture) => {
    let returnData = {
      img: !lecture?.free_lecture_view ? "/images/Icon-akar-lock-on.svg" : '/icons/icon-preview.png',
      link: !lecture?.free_lecture_view ? "" : decryptPassword(lecture?.lesson_link)
    };
   
    if (userLoginStatus.userInfo?.token) {
      const isEnrolled = userLoginStatus?.userInfo?.enrolled_courses?.includes(courseDetails?.course_id);
  
      if (isEnrolled) {
        returnData = {
          img: "/icons/icon-preview.png",
          link: decryptPassword(lecture?.lesson_link),
          };
      }
    }
  
    return returnData;
  }
   
  const getLectureDetails = async ()=>{

  setLoading(true)
  const response = await apiCall.getLectureByCourseId(courseDetails?.course_id);
  if(response)
    {
        setError(false)
        setLoading(false)
        return response
    }
    else
    {
        setLoading(false)
        showAlertMessage("Could not load data from server","fail")
        return 
    }
  }

useEffect(() => {
  const getApiData = async()=>{
    const lecturResponse = await getLectureDetails()
    if(!error)
    {
        setLecturesDetails(lecturResponse)
    }
  } 
  getApiData()
}, [courseDetails]);

  const lectures = [
    {
      lectureNumber: 1,
      title: 'Convallis vivamus at cras porta nibh velit aliquam',
      duration: '52:54',
      lockIcon: '/images/Icon-akar-lock-on.svg'
    },
    {
      lectureNumber: 2,
      title: 'Sed felis eget velit aliquet sagittis id consectetur',
      duration: '48:20',
      lockIcon: '/images/Icon-akar-lock-on.svg'
    },
    {
      lectureNumber: 3,
      title: 'Morbi tristique senectus et netus et malesuada',
      duration: '11:03',
      lockIcon: '/images/Icon-akar-lock-on.svg'
    },
    {
      lectureNumber: 4,
      title: 'Ut tristique et egestas quis ipsum suspendisse',
      duration: '38:15',
      lockIcon: '/images/Icon-akar-lock-on.svg'
    },
    {
      lectureNumber: 5,
      title: 'Vestibulum sed arcu non odio euismod lacinia',
      duration: '41:12',
      lockIcon: '/images/Icon-akar-lock-on.svg'
    }
  ];


  return (
    <div className="container mt-5 curriculam-main">
      <div className="row row-class">
        <div className="col-lg-6 curriculam-start">
          <div className='d-flex align-items-center mb-3'>
            <h2 className="h1_main">Curriculum </h2>
            
          </div>
          <div className="list-group">
          {lecturesDetails.map((lecture, index) => (
        <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
          <div>
            <h5 className='lecture-class'>Lectures {index+1}</h5>
            <p>{lecture.lesson_title}</p>
          </div>
          <div className="text-right">
            <span>{lecture.lesson_duration}</span>
            
            <img 
              className='img-class' 
              style={getLectureStatus(lecture).link ? {cursor:"pointer"}:{cursor:"auto"}}
              onClick={()=>{
                let driveLink =  getLectureStatus(lecture).link;
                if(driveLink)
                {
                  setShowVideoLecture(driveLink)
                }
              }} 
              src={getLectureStatus(lecture).img}
              alt={`Lock icon for lecture ${index+1}`} 
            />
          </div>
        </li>
      ))}
    </div>
        </div>
        <div className="col-lg-6">
          <div className="card">
            <img className="card-img-top" src="/images/curriculam.png" alt="Course" />
            <div className="card-body">
              {
                userLoginStatus.userInfo?.enrolled_courses.find(id => id === courseDetails?.course_id)
                ?
                <h5 className="card-title">Purchased</h5>
                :
                (
                  !courseDetails?.free_view 
                    ? 
                  <h5 className="card-title">{`PKR ${courseDetails?.course_price}/-`}</h5> 
                    :
                  <h5 className="card-title">FREE</h5>
                ) 
              }
              <div className='d-flex Box-main'>
                <img src='/images/Group-2037.svg'></img>
                <h4 className='box-text'>Lectures</h4>
                <h5 className='span-text'>{`${lecturesDetails?.length ?? 0} Lessons`}</h5>

              </div>
              <hr className='divider'></hr>
              <div className='d-flex Box-main'>
                <img src='/images/Group-2038.svg'></img>
                <h4 className='box-text'>Level  </h4>
                <h5 className='span-text'>{Settings.enumCourseLevels.find(id => id.value === courseDetails?.course_level)?.label}</h5>
                
              </div>
              <hr className='divider'></hr>
              <div className='d-flex Box-main'>
                <img src='/images/Group-2042.svg'></img>
                <h4 className='box-text'>Topic </h4>
                <h5 className='span-text'>Chemistry</h5>
              </div>
              {
                userLoginStatus.userInfo?.enrolled_courses.find(id => id === courseDetails?.course_id) || courseDetails?.free_view
                ?
                <></>
                :
                <button className="connect-button"
                  onClick={()=>{dispatch(addToCart(courseDetails,{enrolled_courses: userLoginStatus?.userInfo?.enrolled_courses,enrolled_past_papers: userLoginStatus?.userInfo?.enrolled_past_papers}))}}
                >Add To Cart</button>
              }
             
            </div>
          </div>
        </div>
      </div>
      {showPreview && 
         <Modal status={showPreview} closeModal={closeModal}> 
            <TestMarksPopup closeModal={closeModal}  crossbutton={false}/>
         </Modal> 
      }
      {
        showVideoLecture &&
        <VideoPlayer toogleState={showVideoLecture}  setToogleState={setShowVideoLecture}/>
      }
    </div>
  );
}

export default Curriculum;