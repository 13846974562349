import React, { useState, useEffect, useRef } from 'react';
import "./CommonComponentsStylings.css";
import CommonCalls from '../../CommonAPICalls/CommonCalls';
import Settings from "../../CommonAPICalls/Settings";

const MultiSelect = ({ defaultValues, onChange, name, Label, enableSearch=false }) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [userOptions, setUserOptions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const dropdownRef = useRef(null);

  const pageSize = 50;

  const apiCall = new CommonCalls();

  useEffect(() => {
    setSelectedValues(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    if (isDropdownOpen && pageNumber === 1) {
      getUserOptions(pageNumber, pageSize);
    }
  }, [isDropdownOpen]);

  useEffect(() => {
    if (enableSearch) {
      const timer = setTimeout(() => {
        setUserOptions([])
        getSearchedUserOptions(searchQuery); 
        
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      setUserOptions(userOptions);
    }
  }, [searchQuery, enableSearch]);

  const getUserOptions = async (pageNumber, pageSize) => {
    setIsFetching(true);
    
    const response = await apiCall.getSystemUsers(pageNumber, pageSize); 
    
    if (Array.isArray(response)) { 
      setHasError(true);
      setIsFetching(false);
      return;  
    }

    if (response && response.data) {
      const users = response?.data?.map(item => ({
        value: item?.user_id,
        label: `${item?.full_name} - ${(Settings.enumTypes?.find(val => val.value == item?.student_category))?.label || 'No Student Category'}`
      }));

      if (users) {
        setUserOptions(prevOptions => [...prevOptions, ...users]);
      }
    }
    
    setIsFetching(false);
  };
  const getSearchedUserOptions = async (searchName) => {
    setIsFetching(true);
    
    const response = await apiCall.getSystemUsers("","",searchName); 
    
    if (Array.isArray(response)) { 
      setHasError(true);
      setIsFetching(false);
      return;  
    }

    if (response && response.data) {
      const users = response?.data?.map(item => ({
        value: item?.user_id,
        label: `${item?.full_name} - ${(Settings.enumTypes?.find(val => val.value == item?.student_category))?.label || 'No Student Category'}`
      }));

      if (users) {
        setUserOptions(prev => users);
      }
    }
    
    setIsFetching(false);
  };

  const handleSelectChange = (value) => {
    let updatedValues;
    if (selectedValues.includes(value)) {
      updatedValues = selectedValues.filter(v => v !== value);
    } else {
      updatedValues = [...selectedValues, value];
    }
    setSelectedValues(updatedValues);
    onChange(updatedValues, name);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight - 5 && !isFetching && !hasError) { 
      setPageNumber(prevPageNumber => prevPageNumber + 1);
    }
  };

  useEffect(() => {
    if (pageNumber > 1 && !hasError) {
      getUserOptions(pageNumber, pageSize);
    }
  }, [pageNumber]);

  return (
    <div className='w-100 h-100 d-flex flex-column align-items-center justify-content-end py-2 multi-select-main'>
      <div className='w-100 multi-select-inp d-flex align-items-center justify-content-between px-2' onClick={toggleDropdown}>
        <p>{Label}</p>
        <img src='/images/icons/dropdown-icon.svg' alt="arrow icon"/>
      </div>
      {isDropdownOpen && (
        <div className="dropdown-menu pt-0" ref={dropdownRef} onScroll={handleScroll}>
          {enableSearch && (
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="dropdown-search-input w-100 p-1 pt-2"
              style={{ background: "#F9F9F9" }}
            />
          )}
          {userOptions.map(option => (
            <div key={option.value} className="dropdown-item">
              <input
                type="checkbox"
                checked={selectedValues.includes(option.value)}
                onChange={() => handleSelectChange(option.value)}
              />
              <p className='mb-0'>{option.label}</p>
            </div>
          ))}
          {isFetching && <p className='ps-3 pe-3 mb-0'>Loading more users...</p>} 
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
