import React, { useState } from 'react';
import './ContactPage.css';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { validateForm } from '../UI Helpers/HelperFunctions/Helper';
import { showAlertMessage } from '../MasterComponents/CommonFunctionalities';
import CommonCalls from '../CommonAPICalls/CommonCalls';
import FieldsRenderer from '../UI Helpers/InputFieldsRendererCommon/InputFieldsRendererComponent';
import Settings from '../CommonAPICalls/Settings';
import { useNavigate } from 'react-router-dom';

const apiCall = new CommonCalls()

const inputsFieldAr = [
  {
    label: "Email",
    className: "col-lg-12",
    inputType: "text",
    name: "email",
    id: "email",
    required:true
  },
    {
      label: "Name",
      className: "col-lg-12",
      inputType: "text",
      name: "name",
      id: "name",
      required:true
    },
    {
      label: "Contact Number (WhatsApp)",
      className: "col-lg-12",
      inputType: "text",
      name: "phone_number",
      id: "phone_number",
      required:true
    },
    {
      label : "Session of Examination",
      className:"col-lg-12",
      inputType:"radioList",
      name:"session_of_examination",
      id:"session_of_examination",
      selectOne:true,
      options : [
        { label: 'May/June 2025', isChecked: false },
        { label: 'OCT/NOV 2024 - Recorded Lectures', isChecked: false },
        { label: 'Other', isOther: true }
      ],
      required:true
    },
    {
      label : "Mode of Learning",
      className:"col-lg-12",
      inputType:"radioList",
      name:"mode_of_learning",
      id:"mode_of_learning",
      selectOne:true,
      options : [
        { label: 'Online', isChecked: false },
        { label: 'On-Campus', isChecked: false },
        { label: 'Recorded lectures', isChecked: false },
        { label: 'HANDOUTS/NOTES', isChecked: false },
        { label: 'Other', isOther: true }
      ],
      required:true
    },
    {
      label: "Level of Study",
      className: "col-lg-6",
      inputType: "dropdown",
      name: "level_of_study",
      id: "level_of_study",
      options: Settings.contactFormStudyLevelOptions,
      required:true
    },
    {
      label: "Area of Residence",
      className: "col-lg-12",
      inputType: "text",
      name: "area_of_residence",
      id: "area_of_residence",
      required:true
    }
  ]
  

const ContactPage = () => {
    const [formInputData, setFormInputData] = useState({})
    const [saveBtn, setSaveBtn] = useState(false)
    const navigate = useNavigate();

    const onSubmitBtnClick = async () => {
      setSaveBtn(true)
                                
      let validationResponse = validateForm(inputsFieldAr,formInputData);
      if(!validationResponse) {
        setSaveBtn(false)
        return
      }

      const response = await apiCall.sendLeadQuestionForm({
        ...formInputData,
        level_of_study: Settings.contactFormStudyLevelOptions?.find((obj) => obj?.value == formInputData?.level_of_study)?.label
      });

      if (response && response.status == 201) {  
        showAlertMessage(response?.message, "success");
        navigate("/thank-you")
        setSaveBtn(false);
      } else { 
        setSaveBtn(false)
        showAlertMessage(response?.message, "fail")
      }
    }
    return (
        <>
        <div className="important-section">
            <div className='container contact-container'>
                <div className="contact-info">
                    <h1 className='h1_main'>Tackle your chemistry fears</h1>
                    <p className='para_main'>Ready to start your O level/IGCSE and A level journey with us? Enroll yourself as soon as possible. Our representative will contact you soon and guide you through the next steps. Don’t miss out on this opportunity to be a chemistry expert!</p>
                    <div className="contact-details d-lg-flex gap-5">
                        <p><img src="/images/telephone-icon.svg" alt="Phone" />+923452494359</p>
                     <p><img src="/images/gmail-icon-contact.svg" alt="Email" className='location-icon' /> chem.moosakhan@gmail.com</p>
                     
                        </div>
                        <div className='d-lg-flex'>
                        <p><img src="/images/location-icon-contact.svg" alt="Location" />A 480 Block 5 Near Sir Syed University Gulshan e Iqbal, Karachi</p>
                    </div>
                    <div className='d-lg-flex gap-5'>
                        <p><img src="/images/whatsapp.svg" alt="Location" />+923452494359</p>
                        <p><img src="/images/youtube.svg" alt="Location" /> Chemistry with moosa khan</p>
                    </div>

                    <div className="social-icons">
                      <a rel="noopener" target='blank' href='https://www.facebook.com/chemwithmk' >
                        <img src="/images/facebook.svg" alt="Facebook" className="icon" />
                      </a>
                          
                        <a rel="noopener" target='blank' href='https://www.instagram.com/chemwithmk' >
                          <img src="/images/insta.svg" alt="Instagram" className="icon" />
                        </a>
                        <a rel="noopener" target='blank' href='https://youtube.com/@chemwithmk' >
                         <img src="/images/you.svg" alt="Youbube" className="icon" />
                        </a>
                    </div>
                </div>
                <div className="contact-form">
                    <h2 className='h1_main'>Let's Get in Touch!</h2>
                    <p className='para_main'>Let's discuss your questions!</p>
                     
                    <div className='col-lg-12'>
                      <FieldsRenderer
                        fieldsArray={inputsFieldAr}
                        updateState={setFormInputData}
                        currentState={formInputData}
                      />
                  
                      <Button onClick={onSubmitBtnClick} variant="dark" type="submit" className="mt-2 submit-button w-100" disabled={saveBtn}>
                      <div><span> Submit </span> {saveBtn ? <output className="spinner-border spinner-border-sm ms-1" aria-hidden="true"></output> : ''}</div>
                    </Button>
                </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default ContactPage;
