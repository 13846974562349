import React, { useEffect, useState } from "react";
import Loader from "../../UI Helpers/Simple Loading/Loader";
import FieldsRenderer from "../../UI Helpers/InputFieldsRendererCommon/InputFieldsRendererComponent";
import { showAlertMessage } from "../../MasterComponents/CommonFunctionalities";
import { validateForm } from "../../UI Helpers/HelperFunctions/Helper";
import { useLocation, useNavigate } from "react-router-dom";
import CommonCalls from "../../CommonAPICalls/CommonCalls";

const apiCall = new CommonCalls();

const inputsFieldAr = [
  {
    label: "Campus Name",
    className: "col-lg-4",
    inputType: "text",
    name: "campus_name",
    id: "campus_name",
    required: true,
  },
];

const SystemCampusesDetailView = () => {
  const [saveBtn, setSaveBtn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formInputData, setFormInputData] = useState("");
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const campusId = params.get("campusid");
  const navigate = useNavigate()

  const getCampusData = async () => {
    const response = await apiCall.getCampusById(campusId);
    if (response) {
      setFormInputData({
        campus_name: response?.campus_name
      });

      setLoading(false);
    }
  };

  useEffect(() => {
    if (campusId) {
      setLoading(true);
      getCampusData();
    } else {
      setLoading(false);
    }
  }, [campusId]);

  const addCampus = async () => {
    setSaveBtn(true);
    let validationResponse = validateForm(inputsFieldAr, formInputData);
    if (!validationResponse) {
      setSaveBtn(false);
      return;
    }
    let apiFunction = async () => {
      return await apiCall.addNewCampus(formInputData);
    };
    if (campusId) {
      apiFunction = async () => {
        return await apiCall.updateCampus(campusId, formInputData);
      };
    }
    const response = await apiFunction(); 
    if (response?.status === 201 || 202) {
      setSaveBtn(false);
      if (response?.status === 201)
        { 
          navigate(-1)
        } 
      showAlertMessage(response?.message, "success");
      return response;
    } else {
      setSaveBtn(false);
      showAlertMessage(response?.message, "fail");
      return;
    }
  };

  return (
    <>
      {loading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "80vh" }}
        >
          <Loader />
        </div>
      ) : (
        <div className="campus-detail-view-container container-fluid py-4 px-4">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setSaveBtn(true);
              addCampus();
            }}
          >
            <div className="w-100 text-end ">
              <button
                type="submit"
                className="connect-button w-fit p-3"
                disabled={saveBtn}
              >
                <div>
                  <span> Save </span>{" "}
                  {saveBtn ? (
                    <output
                      className="spinner-border spinner-border-sm ms-1"
                      aria-hidden="true"
                    ></output>
                  ) : (
                    ""
                  )}
                </div>
              </button>
            </div>
            <FieldsRenderer
              fieldsArray={inputsFieldAr}
              updateState={setFormInputData}
              currentState={formInputData}
            />
          </form>
        </div>
      )}
    </>
  );
};

export default SystemCampusesDetailView; 